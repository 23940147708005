import React from 'react'
import { graphql } from 'gatsby'
import { responsiveImage } from '../../utils/helpers.js'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import Layout from '../../components/layout'
import Hero from '../../components/heroSubpage'
import SEO from '../../components/seo'
import AltSection from '../../components/columnAlternatingStyled'
import Card from '../../components/card'

export default ({ data, location }) => {
  const post = data.markdownRemark
  const cardimage = data.cardimage
  const hero = responsiveImage(post.frontmatter.image.childImageSharp.fluid.srcSetWebp)

  return (
    <div id="sub-page">
      <Layout>
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + hero}
        />

        <Hero
          class="intro-65"
          image={hero}
          foreimage={null}
          forealt={post.frontmatter.imageAltText}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}           
          type="root"
          gradientClass="mask d-flex justify-content-center align-items-center gradient"
        />

        <section className="bg-white">
          <MDBContainer>
            <MDBRow className="pb-5">
              <MDBCol>
              <div
                  className="font-w-400 text-xs-extra-large divlink text-opening-para"
                  dangerouslySetInnerHTML={{__html: post.frontmatter.openingpara}}
                />
                
                <div
                  className="mt-3 pb-5 richtext divlink"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
              </MDBCol>
            </MDBRow>

            {post.frontmatter.section && (
              <>
                {post.frontmatter.section.map((sections, index) => {
                  return (
                    <div key={index}>
                      {/* UNICOM Digital solutions */}
                      {index === 0 ? (
                        <MDBRow className="pb-5">
                          <MDBCol>
                            <div className="about-overview">
                              {post.frontmatter.section.title && 
                              <h3 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-extra-large-1 pb-5">
                                {post.frontmatter.section.title}
                              </h3>
                              }
                              <AltSection
                                key={index}
                                title={sections.title}
                                subtitle={sections.feature[0].title}
                                description={sections.feature[0].description}
                                image={cardimage.childImageSharp.fluid}
                                imageAltText="An example of a UniGW dashboard, used to digitally transform enterprise applications"
                                placement="right"
                                titleClass="font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium text-very-dark-blue"
                                link={sections.feature[0].link}
                                colour="ficon-digital"
                              />
                            </div>
                          </MDBCol>
                        </MDBRow>
                      ) : (
                        <>
                          {/* Integrated solutions */}
                          <MDBRow>
                            <MDBCol className="pr-5">
                              <h3 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-medium pt-5 pb-5">
                                {sections.title}
                              </h3>
                              <p className="text-medium text-gray-dark mt-3">
                                {sections.description}
                              </p>
                            </MDBCol>
                          </MDBRow>

                          <MDBRow>
                            {sections.feature.map((features, index) => (
                              <Card
                                key={index}
                                collg="6"
                                colmd="6"
                                height="3.5rem"
                                title={features.title}
                                subtitle=""
                                description={features.description}
                                image={features.image.childImageSharp.fluid}
                                imageAltText={features.alttext}
                                link={features.link}
                              />
                            ))}
                          </MDBRow>
                        </>
                      )}
                    </div>
                  )
                })}
              </>
            )}
          </MDBContainer>
        </section>
      </Layout>
    </div>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(
      frontmatter: { template: { eq: "other" }, title: { eq: "Solutions" } }
    ) {
      frontmatter {
        meta {
          title
          description 
        }
        title
        subtitle
        ctatext
        ctaslug         
        openingpara
        alttext
        image {
          childImageSharp {
            fluid(maxWidth: 1980, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        section {
          title
          feature {
            title
            image {
              childImageSharp {
                fluid(quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alttext
            description
            linktext
            link
          }
        }
      }
      html
    }
    cardimage: file(name: { eq: "enterprise-application-modernization" }) {
      childImageSharp {
        fluid(maxWidth: 1750, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`